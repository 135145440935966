import React, { useEffect, useState } from 'react';
import useComponent, { EMapType } from '@naviair-utm/react-shared-components';
import { TSettingsMapZoneProperties } from '@naviair-gl/node-shared-interfaces';
import { NavigateFunction, RouteComponentProps, useNavigate, useSearchParams } from 'react-router-dom';
import { Recoil, useRecoilValue } from '../../../Recoil';
import { useSetRecoilState } from 'recoil';
import { addAttribution, addZones, addZonesListerners, LAYER_FILTERS, MAPBOX_LAYERS } from './Helpers';
import { Decimal2DMS } from 'dms-to-decimal';
import './styles.scss';
import {
	EBannerTypes,
	MapBanner,
	MapHeightFilterSelector,
	MapLayerSelector,
	NotamModal,
	TMapLayer,
} from '../../../Components';
import { useTranslation } from 'react-i18next';
import { IGeo, ILatLng } from '@naviair-utm/node-shared-interfaces';
import { removeMarker } from './mapControlHelper';
import mapboxgl from 'mapbox-gl';
import { backendApiHook } from '../../../Api';
import { EBackgroundLayerType } from '../../../interfaces';
import MapLight from '../../../Assets/Images/Map/light-v10-small.png';
import Satellite from '../../../Assets/Images/Map/satellite-streets-v11-small.png';
import VFRImage from '../../../Assets/Images/Map/vfr-small.png';
import { MapButtons } from '../../../Components/MapButtons';

export interface IMapProps extends RouteComponentProps {
	zones: GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.Point | GeoJSON.MultiPolygon, TSettingsMapZoneProperties>;
}

export type TMarkerAddedEvent = {
	geoInfo?: IGeo;
	latLng?: ILatLng;
	features?: mapboxgl.MapboxGeoJSONFeature[];
};

export type TNotamModalState = {
	icao?: string;
	visible: boolean;
	link?: string;
	lngLat?: ILatLng;
	title?: string;
};

export const Map: React.FC<IMapProps> = (props) => {
	const [t] = useTranslation();
	const screenType = useRecoilValue(Recoil.ScreenType.Atom);
	const configuration = useRecoilValue(Recoil.Configuration.Selector);
	const setGLoadingState = useSetRecoilState(Recoil.GeneralLoading.Atom);
	const [getCoordinates, setCoordinates] = useState<string>('');
	const { useMap } = useComponent();
	const getAftnMessages = useRecoilValue(Recoil.Aftn.Atom);
	const availableAftnTypes = configuration.settings.availableAftnTypes;
	const [getModalState, setModalState] = useState<TNotamModalState>({ visible: false });
	const setLatestFetch = useSetRecoilState(Recoil.LatestIcaoFetch.Atom);
	const [selectedBackground, setSelectedBackground] = useState<EBackgroundLayerType>(EBackgroundLayerType.LYST);
	const [mapLayers, setMapLayers] = useState<TMapLayer[]>([]);
	const [getHeightFilterOn, setHeightFilterOn] = useState<boolean>();
	const altitudeFilterFeatureEnabled = configuration.settings.map.altitudeFilterFeatureEnabled;

	// Initialize navigate hook
	const navigate = useNavigate();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { renderMap, mapObj } = useMap(process.env.REACT_APP_MAPBOX_API_KEY ?? '', {
		mapStyle: EMapType.LIGHT,
		zoom: 3,
		logoPosition: 'bottom-right',
		autoResize: true,
		interactive: true,
		dragRotate: false,
		...configuration.settings.mapbox,
	});

	const { getLocation } = backendApiHook();

	const showCoordinates = (map: mapboxgl.Map) => {
		map.on('mousemove', (e) => {
			const coordsConverted = {
				lat: Decimal2DMS(e.lngLat.lat, 'latitude'),
				long: Decimal2DMS(e.lngLat.lng, 'longitude'),
			};
			setCoordinates(`${coordsConverted.lat} ${coordsConverted.long}`);
		});
	};

	useEffect(() => {
		const layers: TMapLayer[] = [
			{ type: EBackgroundLayerType.LYST, icon: MapLight, label: 'Lyst' },
			{ type: EBackgroundLayerType.SATELLIT, icon: Satellite, label: 'Satellit' },
		];
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		configuration.settings.map.vfr?.length > 0 && layers.push({
			type: EBackgroundLayerType.VFR,
			icon: VFRImage,
			label: 'VFR',
		});
		setMapLayers(layers);
	}, []);

	// Get search params
	const [searchParams] = useSearchParams();
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		mapObj?.once('load', () => {
			// Returns true if all elements in the array of needed params for a specific location is included in the search params
			const allLocationParamsSet = ['lat', 'long', 'zoom'].every((paramName) => searchParams.has(paramName));

			// If all 3 is set, fly to the location specified after map load
			if (allLocationParamsSet) {
				mapObj.flyTo({
					center: {
						lon: Number(searchParams.get('long')),
						lat: Number(searchParams.get('lat')),
					},
					zoom: Number(searchParams.get('zoom')),
					speed: 2,
				});
			}
		});
	}, [mapObj, searchParams]);

	/**
	 * Change map style whenever the state is changed
	 */
	useEffect(() => {
		switch (selectedBackground) {
			case EBackgroundLayerType.LYST:
			case EBackgroundLayerType.VFR:
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				mapObj?.setStyle(EMapType.LIGHT);
				break;
			case EBackgroundLayerType.SATELLIT:
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				mapObj?.setStyle(EMapType.SATELLITE);
				break;
			default:
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				mapObj?.setStyle(EMapType.LIGHT);
				break;
		}

		setTimeout(() => {
			// Delayed adding layers to make sure that state is set correctly.
			addAllLayers(navigate);
		}, 300);
	}, [selectedBackground]);

	/* Resize on phone initial load fix.
	* Manual resizer, because MainLayout cannot listen on screenType recoil value. 
	? Naviair GL is implemented with only EScreenType.DESKTOP, because we have no need for mobile nav.
	  TODO: optimize 
	*/
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		mapObj?.resize();
	}, [screenType]);

	const addVFR = () => {
		// Load VFR sources
		const { vfr } = configuration.settings.map;

		vfr.forEach((item) => {
			if (item.visible !== undefined && !item.visible) {
				// Don't add invisible configured VFR sources
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			!mapObj?.getSource(`${item.sourceName}-src`) && mapObj?.addSource(`${item.sourceName}-src`, {
				type: 'raster',
				url: `mapbox://${item.tilesetId}`,
			});

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			!mapObj?.getLayer(`${item.sourceName}-layer-id`) &&
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			mapObj?.addLayer({
				id: `${item.sourceName}-layer-id`,
				type: 'raster',
				source: `${item.sourceName}-src`,
				paint: {
					'raster-resampling': 'linear',
				},
			});
		});
	};

	const addAllLayers = (navigate: NavigateFunction) => {
		/* Add Map Content */
		if (selectedBackground === EBackgroundLayerType.VFR) {
			addVFR();
		}

		// If activeIndicator is false, the zones will be added from elsewhere as the aftn messages need to be loaded beforehand
		addZones(mapObj, props.zones, configuration.settings.map.locationIndicator, getAftnMessages, availableAftnTypes, configuration.settings.map.zoneColors, altitudeFilterFeatureEnabled);
		addZonesListerners(setModalState, navigate, mapObj);
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		mapObj?.once('load', () => {
			addAllLayers(navigate);
			addAttribution(mapObj);
			setGLoadingState(false);
			showCoordinates(mapObj);

			/* Make sure map has right size on load */
			mapObj.resize();

			const popup = new mapboxgl.Popup({
				offset: 10,
				/* Set to 100% maxWidth to avoid linebreak. */
				maxWidth: '100%',
				className: 'pointPopup',
				closeButton: false,
				closeOnClick: false,
			});

			/* Fetch location information on hover and set popup content. Don't show on naviair.gl */
			mapObj.on('mouseenter', 'aftn-points', (evt) => {
				const feature = evt.features?.[0];
				const icao: string = feature?.properties?.name;
				const coordinates = feature?.geometry.coordinates;
				popup.setLngLat(coordinates).addTo(mapObj).addClassName('hidden');
				getLocation(icao).then((res) => {
					/* In case of multiple search results, always look for match. */
					const result = res.find((res) => res.indicator.includes(icao));
					/* Don't show popup on naviair.gl */
					const html = `<b>${result?.indicator}</b> ${result?.name}`;
					/* Set new HTML and show*/
					popup.setHTML(html).removeClassName('hidden');

					/* cache fetch so we don't have to fetch same data again, if user clicks on point */
					setLatestFetch(result);
				});
			});

			mapObj.on('mouseleave', 'aftn-points', () => {
				/* Remove popup and make sure we hide */
				popup.remove().addClassName('hidden');
			});

		});
	}, [mapObj, getAftnMessages]);


	/**
	 * Handle the altitude filter checkbox.
	 * Works by applying a filter that will match a property set on the layer
	 */
	if (altitudeFilterFeatureEnabled) {
		useEffect(() => {
			if (mapObj) {
				if (getHeightFilterOn) {
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_PATTERN_ID, LAYER_FILTERS.NOTAM_POLYGON_PATTERN_ID);
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID, LAYER_FILTERS.NOTAM_FILL_ID);
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_LINE_ID, LAYER_FILTERS.NOTAM_LINE_ID);
				} else {
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_PATTERN_ID, LAYER_FILTERS.NOTAM_POLYGON_PATTERN_ID_ALTITUDE_FILTER);
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID, LAYER_FILTERS.NOTAM_FILL_ID_ALTITUDE_FILTER);
					mapObj.setFilter(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_LINE_ID, LAYER_FILTERS.NOTAM_FILL_ID_ALTITUDE_FILTER);
				}
			}
		}, [getHeightFilterOn]);
	}


	return (
		<>
			{renderMap}
			<>
				<div className={'mapBannerContainer'}>
					<MapBanner type={EBannerTypes.WARNING} message={t('Alle data til operationelt brug skal bekræftes hos nærmeste AIS-kontor')} screenType={screenType}/>
					<MapBanner screenType={screenType} hide={!altitudeFilterFeatureEnabled || getHeightFilterOn} type={EBannerTypes.DANGER} message={t('Du får kun vist NOTAMs under FL195!')} />
					{/* You can add more <MapBanner> in this container, should you want to */}
				</div>

				<div className={'mapCoordinates'}>
					<p>{`${t('Koordinater')}: ${getCoordinates}`}</p>
				</div>

				<NotamModal
					{...getModalState}
					routeProps={props}
					mapRef={mapObj}
					onClose={() => {
						setModalState({ visible: false });
						removeMarker();
					}}
				/>

				<MapButtons screenType={screenType}>
					{altitudeFilterFeatureEnabled &&
						<MapHeightFilterSelector screenType={screenType} onChange={setHeightFilterOn} />
					}
					<MapLayerSelector screenType={screenType} selectableLayers={mapLayers} changeEvent={setSelectedBackground} />
				</MapButtons>
			</>
		</>
	);
};
